import React, { Fragment } from 'react'
import { productVariantPropType } from 'lib/prop-types'
import formatMoney from 'lib/format-money'
import Categories from 'components/Products/Categories'
import parseToUrl from 'lib/parse-to-url'
import Image from 'components/Image'
import imageUrlForProduct from 'lib/image-url-for-product'

const ProductPreview = props => {
  const { product } = props

  return (
    <Fragment>
      <div className="product-img--small">
        <Image
          name={imageUrlForProduct(product)}
          size="huge"
          alt={product.defaultImage.alt}
        />
      </div>
      <div className="product-preview__content">
        <Categories
          categories={product.inCategories}
          namespace={`productPreview-${product.id}`}
        />
        <div className="manufacturer-logo">
          <Image
            name={`manufacturer/${parseToUrl(product.manufacturer)}`}
            size="small"
          />
        </div>
        <h2 className="product--title">{product.title}</h2>
        {product.price === 0 ? null : formatMoney(product.price)}
      </div>
    </Fragment>
  )
}

ProductPreview.propTypes = {
  product: productVariantPropType,
}

export default ProductPreview
