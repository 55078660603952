import React from 'react'
import Layout from 'components/Layout'
import ContactFormFields from 'components/ContactFormFields'
import Button from 'components/Button'
import Link from 'components/Link'
import createStore from '../lib/util/create-store'
import { urlLocationPropType } from 'lib/prop-types'
import { navigate } from 'gatsby'
import ProductPreview from 'components/Products/ProductPreview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const contactStore = createStore('contact')

const defaultContact = {
  firstName: '',
  lastName: '',
  street: '',
  zipCode: '',
  city: '',
  email: '',
  phone: '',
  notes: '',
}

class Order extends React.Component {
  constructor(props) {
    super(props)
    this.state = contactStore.load() || defaultContact
    if (
      typeof window !== 'undefined' &&
      (props.location.state == null ||
        typeof props.location.state.type === 'undefined')
    ) {
      navigate('/')
    }
    const itemInfo = props.location.state || {}

    this.type = itemInfo.type
    this.item = itemInfo.item
    this.isCustom = itemInfo.isCustom
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      contactStore.save(this.state)
    })
  }

  render() {
    return this.props.location.state ? (
      <Layout>
        <div className="section bg__flex--grey">
          <div className="section__inner section--padded-small">
            <div className="wrap__box--pad-l bt__3--dark">
              <div className="columns__box">
                <div className="column--half">
                  <h3 className="wrap__box--subheadline">
                    Sie bestellen folgenden Artikel
                  </h3>
                  <Link
                    to={this.props.location.state.history}
                    className="product-preview__box b-r--3 hover--shadow"
                  >
                    <ProductPreview product={this.item} />
                  </Link>
                </div>
                <form className="column--half">
                  <ContactFormFields
                    handleChange={data => this.handleChange(data)}
                    state={this.state}
                  />

                  <div className="radio__wrap">
                    <input
                      type="radio"
                      id="payment-method"
                      className="radio--default"
                      checked
                      disabled
                    />
                    <label htmlFor="payment-method">
                      <div className="radio">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                      Kauf auf Rechnung
                    </label>
                  </div>
                  <div className="confirm__actions">
                    <div className="confirm__submit">
                      <Button className="btn--primary">
                        Bestellung absenden
                      </Button>
                    </div>
                    <Link
                      to={this.props.location.state.history}
                      className="reverse-link"
                    >
                      Zurück
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    ) : null
  }
}

Order.propTypes = { location: urlLocationPropType }

export default Order
